:root {
  --portal-theme-primary: #045dbb;
  --portal-theme-secondary: rgba(4, 93, 187, 0.06);
  --portal-theme-text: rgb(99, 115, 129);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
}

h3 {
  font-size: 1.5rem !important;
}

.pointer {
  cursor: pointer;
}

.cross {
  position: absolute;
  right: 19px;
  top: 12px;
}

.bar-img {
  text-align: center !important;
  align-items: center;
  width: 100% !important;
  height: 100% !important;

  margin: 10px 0px;
}

.box {
  margin: 0px 10px !important;
}

.pages-h4 {
  margin-top: 10px;
  margin-left: 10px;
}

.total-products,
.total-orders,
.today-orders,
.total-categories,
.total-customers,
.total-employees,
.total-shipments,
.pending-orders,
.delivered-orders,
.cancelled-orders,
.total-vendors {
  box-shadow: none;
  text-align: center;
  padding: 16px 0;
  border-radius: 15px;
}

.total-products {
  background-color: #f34875 !important;
  color: #f34875 !important;
}
.today-orders {
  background-color: #c985b2 !important;
  color: #c985b2 !important;
}
.cancelled-orders {
  background-color: #ffa600 !important;
  color: #ffa600 !important;
}
.delivered-orders {
  background-color: #f95d6a !important;
  color: #f95d6a !important;
}
.pending-orders {
  background-color: #00d284 !important;
  color: #00d284 !important;
}

.total-orders {
  background-color: #4595ff !important;
  color: #4595ff !important;
}

.total-shipments {
  background-color: #f7ad28 !important;
  color: #f7ad28 !important;
}

.total-employees {
  background-color: #6f3ad4 !important;
  color: #6f3ad4 !important;
}

.total-categories {
  background-color: #3cbea2 !important;
  color: #3cbea2 !important;
}

.total-customers {
  background-color: rgb(230, 66, 66) !important;
  color: rgb(230, 66, 66) !important;
}

.total-vendors {
  background-color: #802d71 !important;
  color: #802d71 !important;
}

.sidebar-icons {
  font-size: 16px;
  width: 35px;
}

.menus-list {
  color: var(--portal-theme-text) !important;
  font-size: 14px !important;
  width: 100%;
}

.child-menus-list {
  padding-left: 40px !important;
}

.child-menus-list span {
  font-size: 14px !important;
}

.menuActive {
  color: var(--portal-theme-primary) !important;
  background-color: var(--portal-theme-secondary) !important;
}

.diary-icon-remove {
  color: red;
  fill: red;
  margin-bottom: 0px;
  cursor: pointer;
}

.diary-icon-add {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.menu {
  color: var(--portal-theme-text) !important;
}

.button-logout {
  margin-top: 20px;
  font-weight: 400;
  font-size: 1rem;
  background-color: var(--portal-theme-secondary);
  color: white;
  border: none;
  border-radius: 9px;
  padding: 5px 15px;
  width: 100%;
}

.font-size-login {
  font-weight: 400px !important;
}

.bg-image {
  background-image: url("https://admin.positivitychallenge.app/static/illustrations/illustration_login.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.input-fields {
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid rgb(170, 170, 170);
  width: 300px;
  padding: 5px;
}

.login-button {
  margin-top: 5px;
  padding: 5px 72px;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 5px;
  width: 300px;
}

.p-text {
  color: rgb(170, 170, 170);
  font-size: 12px;
}

.forgot-text {
  font-size: 15px !important;
  margin: 18px 0px !important;
  margin-bottom: 1px !important;
}

.forgot-email {
  justify-content: center;
  align-items: center;
  margin: auto;
}

.main-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.login-fields {
  max-width: 480;
  margin: auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.card-title-text {
  padding: 0 5;
  margin-top: 10;
  margin-bottom: 5;
}

.picture-card {
  width: 100%;
  max-width: 464;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mui-bg-custom-table {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
  border-radius: 4px !important;
}

.table-header span {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 8px 16px !important;
}

.small-contained-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.875em;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(25, 97, 222);
  box-shadow: rgb(25 97 222 / 24%) 0px 8px 16p;
}
.download-button {
  -webkit-box-pack: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-decoration: none;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(25, 97, 222);
  box-shadow: rgb(25 97 222 / 24%) 0px 8px 16p;
}
.small-contained-button-delete {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.875em;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(222, 25, 25);
  box-shadow: rgba(250, 25, 25, 0.24) 0px 8px 16p;
}

.css-1ygcj2i-MuiTableCell-root {
  font-weight: 600;
}

.inputs-fields {
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.css-9cgc89-MuiTableFooter-root {
  display: flex !important;
}

.profile-menu {
  font-size: 17px;
  margin: 0px 5px;
  color: var(--portal-theme-text);
  padding: 0px 12px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none !important;
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 0;
  border-bottom: 1px solid rgb(229, 229, 229);
}

.mui-table-foote .MuiTablePagination-root {
  width: 98%;
}

.MuiDrawer-paperAnchorRight {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  box-shadow: none;
  background-image: none;
  position: relative;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 0px;
  width: 380px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.div-style {
  border-bottom: 1px solid #cacaca;
  padding: 8px;
}

[role="button"] {
  cursor: pointer;
}

.profile-menu-email {
  margin-top: -13px;
  font-size: 15px;
  color: #068ce5;
}

.profile-menu-logout {
  margin: auto !important;
  margin-top: 5px !important;
  width: 100%;
  color: #ffffff !important;
  background-color: #068ce5 !important;
  font-size: 15px !important;
  padding: 2px 15px !important;
}

.employe-profile .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  border: 2px solid #1976d2 !important;
  background-color: rgba(25, 118, 210 0.05) !important;
}

/* scrollbar  */

/*  width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* /Handle* */
::-webkit-scrollbar-thumb {
  background: #d1cfcf;
  border-radius: 10px;
}

/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

.mui-table-search-input-box
  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11.5px 0px !important;
}

.info-cards {
  text-align: center !important;
  padding: 12px 0 !important;
  border-radius: 3px !important;
}

.heading {
  text-align: center !important;
}

.upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1a0z2gx {
  overflow: auto !important;
  height: auto;
}

.simplebar-placeholder {
  height: auto !important;
}

.employee-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button {
  background-color: var(--portal-theme-secondary) !important;
  color: var(--portal-theme-primary) !important;
}

.tox-statusbar {
  display: none !important;
}

.mui-table-footer {
  display: flex !important;
  justify-content: space-between;
}

.mui-table-footer p {
  margin-bottom: 0 !important;
}

.tox-statusbar__branding {
  display: none !important;
}

.profile-menu-icons {
  width: 25px !important;
  height: 25px !important;
  color: #068ce5 !important;
  margin-right: 12px !important;
}

.sidebar-menu-text .MuiListItemText-primary {
  font-size: 14px !important;
}

.privileges-svg-icon {
  color: rgb(58, 120, 218);
  cursor: pointer;
}
.cursor-table {
  cursor: pointer;
}

.small-contained-button {
  border: 2px solid !important;
  padding: 8px 13px !important;
}
.small-contained-button-delete {
  border: 2px solid !important;
  padding: 8px 13px !important;
}

.positioning-menu {
  left: 24% !important;
  top: 0% !important;
}
@media (max-width: 767px) {
  .positioning-menu {
    left: 0% !important;
    top: 0% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .positioning-menu {
    left: 15% !important;
    top: 0% !important;
  }
}

.simplebar-wrapper {
  height: auto !important;
}

.preview {
  position: relative;
}

.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.preview span:hover {
  cursor: pointer;
}
.delete-icon span:hover {
  cursor: pointer;
}

.preview span {
  position: absolute;
  right: 3px;
  /* top: -10px; */
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.circular-progress {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.circular-progress svg {
  color: var(--portal-theme-primary);
}

.centerd {
  margin-left: 50%;
  margin-top: 20%;
}

.image-container {
  width: 90%;
  max-width: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form-container {
  width: 70%;
  max-width: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 70vh;
}

.profile-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-left: 110px;
  position: relative;
}

.profile-name {
  color: #068ce5;
  font-weight: bold;
  font-size: large;
}

.edit-icon {
  border-radius: 100%;
  position: absolute;
  margin-left: 255px;
  color: #068ce5;
  cursor: pointer;
}

.dashboard-card-icons {
  margin: auto;
  margin-top: 10px;
  display: flex;
  border-radius: 25%;
  align-items: center;
  width: 40px;
  height: 40px;
  justify-content: center;
  margin-bottom: 24px;
  background-color: #ffffff;
}

.dashboard-card {
  box-shadow: none;
  text-align: center;
  padding: 16px 0;
  border-radius: 15px;
}

.dashboard-sidebar {
  align-items: center;
  border-radius: 15;
  cursor: pointer;
  margin-bottom: 0%;
}

.layout-index {
  display: flex;
  min-height: 100%;
  overflow: hidden;
}

.change-password-modal {
  display: flex;
  justify-content: space-between;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

.edit-profile-icon {
  position: relative;
  border-radius: 25%;
}

.edit-profile-icon-position {
  position: absolute;
  right: -10px;
  top: 66px;
  color: #068ce5;
}

.edit-profile-icon img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.change-password {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: #ffffff !important;
  box-shadow: 24 !important;
  padding: 30px !important;
  width: 480px !important;
  max-width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* table form card */
.table-form-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 1px;
  width: 100%;
  padding: 15px;
}

.table-form {
  row-gap: 24px;
}
.ListSubheader {
  /* margin: 20px!important; */
  font-weight: bold !important;
  font-size: medium !important;
}
.MenuItem {
  padding-left: 30px !important;
  font-weight: normal !important;
  font-size: small !important;
}
.task_heading_feedback {
  color: #0490aa;
  /* color:#04aa56; */
  font-weight: bolder;
}

.task_icon_color {
  color: #9f9fa0;
}
.task_icon_copy {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.task_button_plus_color {
  color: #12488b;
  /* color:#008001; */
}
.task_button_text_color {
  color: #1d2226;
  padding-left: 5px;
  font-weight: 400;
}
.task_button_plus_assigned {
  color: #07216b;
}
.filter_button {
  border-radius: 5px;
  border: 1px solid #dbdfe3;
  width: 100%;
  padding: 10px;
  background-color: #f6f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 41px;
  /* color: black; */
  /* font-weight: 600; */
}
.filter_button:hover {
  box-shadow: rgb(25 97 222 / 24%) 0px 8px 16p;
  /* border: 1px solid  rgb(25, 97, 222); */
  border: 1px solid #abc3dc;
}

.buttons_in_task_list {
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: #9f9fa0;
  vertical-align: top !important;
  padding-bottom: 5px;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  /* content: ""; */
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid transparent !important;
  pointer-events: none;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  /* content: "\00a0"; */
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid transparent !important;
  pointer-events: none;
}

.new-variation-form {
  padding: 15px 15px 0px 15px;
}

.typography-font {
  font-size: 17px !important;
}

.typography-font-heading {
  font-size: 17px !important;
  font-weight: bold !important;
}

.see-less-more-mui-table {
  color: var(--portal-theme-primary);
  cursor: pointer;
  z-index: 9;
}

.no-access-string {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 50vh;
}
.send_button_icon {
  border: none !important;
  background-color: transparent !important;
  color: #045dbb !important;
  padding: 5px !important;
}
.send_button_icon:active {
  border: none !important;
  background-color: #e2e7ec !important;
}
.home_page_header {
  font-size: "1rem";
}
.general_setting_page_header {
  font-size: "1rem";
}
.content_setting_page_header {
  font-size: 20px;
}

.button-margin {
  margin-left: 10px;
  margin-top: 10px !important;
}
.page_title,
.product_heading_in_variation {
  padding-left: 10px;
  font-weight: 500;
  font-size: 1rem;
  color: #045dbb;
}
.task_title {
  padding-left: 13px;
  cursor: default;
}
.description-in-task-setting {
  background: #1d2226dd;
  color: #ebfcfc;
  padding: 20px;
  padding-bottom: 5px;
}

.assigned-menu {
  display: flex;
  justify-content: left;
}

.task-click-option {
  padding: 5px 0px;
  /* border:1px solid blue; */
  display: flex;
  justify-content: right;
  align-items: center;
}

.task-select-date {
  /* border:1px solid blue; */
  padding: 7px 0px;
  color: #007ea1;
  cursor: pointer;
  text-decoration: underline;
  /* font-size: 0.4rem; */
}

@media screen and (max-width: 767px) {
  /* styles for screens narrower than 768px */
  .task-assigned-menu {
    /* border:1px solid red; */
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .task-buttons-menu {
    /* border:1px solid red; */
    padding: 2px 0px;
    display: flex;
    justify-content: right;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .task-assigned-menu {
    /* border:1px solid green; */
    display: flex;
    justify-content: center;
  }
  .task-buttons-menu {
    /* border:1px solid green; */
    padding: 2px 0px;
    display: flex;
    justify-content: right;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 904px) {
  .task-select-date {
    font-size: 10px;
  }
}
@media screen and (min-width: 905px) and (max-width: 1163px) {
  .task-select-date {
    padding: 12px 0px;
    font-size: 10px;
  }
}
/* Style the message container */
.message-container {
  /* background-color: #f2f2f2; */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-top: 15px;
}
/* Style the message text */
.message-text {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  text-align: center;
  margin-top: 15px;
}

.datepicker {
  max-height: 0px;
  overflow-y: hidden;
}

/* Fixed - Extra padding in card */
.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker {
  top: -41px;
}
.date_picker {
  z-index: 9999;
}
.shipment-received-cards {
  font-size: large !important;
  font-weight: bold !important;
  margin-top: 10px !important;
}
.custom-file-upload {
  display: inline-block;
  background-color: #f9a826;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-upload img {
  height: 20px;
  margin-right: 8px;
}
.size {
  font-weight: 500;
}
.border-div {
  border-bottom: solid 0.3px rgb(207, 205, 205);
}
.gallery-view .css-pdct74-MuiTablePagination-selectLabel {
  margin-top: revert !important;
}
.gallery-view .css-levciy-MuiTablePagination-displayedRows {
  margin-top: revert !important;
}
.gallery-view .MuiTablePagination-selectLabel.css-1chpzqh {
  margin-top: revert !important;
}
.gallery-view .MuiTablePagination-displayedRows.css-1chpzqh {
  margin-top: revert !important;
}

.qtyField .qtyBtn {
  width: 30px;
  height: 35px;
  border-radius: 0;
  border: none;
  float: left;
  color: #000;
  font-weight: 100px;
}
.qtyField {
  display: table;
  margin: 0 auto;
}
.payment_box {
  width: 40%;
  float: right;
}
.payment_box_detail {
  float: right;
}
.solid-border {
  border: 1px solid #e8e9eb;
  padding: 10px 0px 10px 10px;
  margin-bottom: 10px;
}
.cart__shipping {
  font-style: italic;
  font-size: 13px;
  padding: 12px 0;
}
.cart__footer .cart__subtotal-title {
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.02em;
}
.cart-p {
  text-align: right;
}

.cart__subtotal {
  font-weight: 700;
  padding-left: 15px;
  display: inline-block;
}
.size-table {
  font-size: 14px;
}
.badge-style {
  align-items: center;
  justify-content: center;
  padding: 6px 12px !important;
  text-transform: capitalize;
}
.fixed-width {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.circular-progress-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.payment-box {
  margin-bottom: 2;
  padding: 0px 4px;
}

.fixedbutton {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 1;
}

.fixedbutton2 {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 1;
}

.tox-notifications-container {
  visibility: hidden !important;
}
